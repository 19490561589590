import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const GTMPageView = () => {
    const location = useLocation();
    const firstRender = useRef(true); // Flag per il primo rendering

    useEffect(() => {
        // Se è il primo rendering, non inviare il pageview e resettare il flag
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "pageview",
            page_path: location.pathname + location.search,
        });

    }, [location]);

    return null;
};

export default GTMPageView;