import { useEffect } from 'react';

const GTMLoader = ({ gtmId, defaultConsentSet }) => {
    useEffect(() => {
        const loadGTM = () => {
            if (window.gtmInitialized) return;
            window.gtmInitialized = true;

            // // Controlla se il consenso predefinito è stato configurato
            // if (!defaultConsentSet) {
            //     console.warn('Default consent non impostato. Google Tag Manager non verrà caricato.');
            //     return;
            // }

            // Carica lo script di GTM
            const gtmScript = document.createElement('script');
            gtmScript.async = true;
            gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
            gtmScript.setAttribute('data-cookieconsent', 'ignore');
            document.body.appendChild(gtmScript);

            // Aggiunge il noscript
            const noscript = document.createElement('noscript');
            const iframe = document.createElement('iframe');
            iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
            iframe.height = "0";
            iframe.width = "0";
            iframe.style.display = "none";
            iframe.style.visibility = "hidden";
            iframe.setAttribute('data-cookieconsent', 'ignore');
            noscript.appendChild(iframe);
            document.body.insertBefore(noscript, document.body.firstChild);
        };

        loadGTM();
    }, [gtmId]);

    return null;
};

export default GTMLoader;
