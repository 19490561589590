import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { BaseProvider } from './context/BaseContext';
import { CompanyProvider } from './context/CompanyContext';
import Header from './components/Header';
import GTMPageView from "./components/GTMPageView"; // Per tracciare i cambi di rotta

import i18n from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';

const Home = React.lazy(() => import('./pages/Home'));
const Menu = React.lazy(() => import('./pages/Menu'));
const Gallery = React.lazy(() => import('./pages/Gallery'));
const Contact = React.lazy(() => import('./pages/Contact'));
const Anniversary = React.lazy(() => import('./pages/Anniversary'));
const Footer = React.lazy(() => import('./components/Footer'));
const NotFound = React.lazy(() => import('./pages/NotFound'));

// Configurazione iniziale di i18next
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: require('./locales/support/en.json') },//require('./locales/en.json') }, // Percorso ai file di traduzione
      it: { translation: require('./locales/support/it.json') },//require('./locales/it.json') },
      // ... altre lingue
    },
    lng: 'it', // Lingua iniziale
    fallbackLng: 'it', // Lingua di riserva
    interpolation: { escapeValue: false },
  });

const App = () => {
  const helmetContext = {};

  return (
    <Router>
      <I18nextProvider i18n={i18n}>
        <BaseProvider>
          <CompanyProvider>
            <HelmetProvider context={helmetContext}>
              {/* Tracciamento PageView per ogni cambio di rotta */}
              <GTMPageView />

              <Routes>
                {/* Wrapper con layout */}
                <Route
                  element={
                    <>
                      <Header />

                      {/* Il contenuto delle pagine verrà inserito qui */}
                      <Suspense fallback={<></>}>
                        <Outlet />
                      </Suspense>

                      <Footer />
                    </>
                  }
                >

                  {/* Altre pagine con layout comune */}
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/:lang" element={<Home />} />
                  <Route path="/:lang/menu" element={<Menu />} />
                  <Route path="/:lang/bar" element={<Menu />} />
                  <Route path="/:lang/gallery" element={<Gallery />} />
                  <Route path="/:lang/contact" element={<Contact />} />
                  <Route path="/:lang/anniversary" element={<Anniversary />} />
                </Route>

                {/* Pagina NotFound con layout diverso */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </HelmetProvider>
          </CompanyProvider>
        </BaseProvider>
      </I18nextProvider>
    </Router>
  );
};

export default App;
